<template>
  <div class="activation">
    <div class="activation__top mb-16 mb-tablet-32">
      <router-link to="/" class="activation__logo">
        <base-logo-vertical class="mobile" />
        <base-logo-horizontal class="tablet-desktop" />
      </router-link>
    </div>

    <h1 class="activation__heading heading color-primary mb-16 mb-tablet-24">
      Aktywacja konta
    </h1>

    <base-spinner v-if="loading" large class=" mb-32 mb-tablet-64" />
    <div v-else class="activation__content mb-32 mb-tablet-64">
      <div v-if="responseStatus === 200">
        <p class="mb-24 activation__paragraph">
          Dziękujemy. Twoje konto zostało aktywowane.
        </p>

        <div class="activation__buttons">
          <base-button
            tag="router-link"
            to="/app/account/#modules"
            theme="secondary"
            class="activation__button mb-4 mb-tablet-0"
            uppercase
            plain
            >Moje moduły</base-button
          >
          <base-button
            tag="router-link"
            to="/app"
            theme="primary"
            class="activation__button mb-4 mb-tablet-0"
            uppercase
            >Zobacz serwis</base-button
          >
        </div>
      </div>

      <div v-else>
        <p v-if="responseStatus === 400" class="mb-24 activation__paragraph">
          <span class="color-error">Aktywacja się nie powiodła.</span> Konto
          zostało usunięte lub dane aktywacyjne straciły ważność.
        </p>
        <p
          v-else-if="responseStatus === 403"
          class="mb-24 activation__paragraph"
        >
          Twoje konto zostało już wcześniej aktywowane.
        </p>
        <p v-else>
          <span class="color-error">Aktywacja się nie powiodła.</span> Wystąpił
          błąd.
        </p>

        <div class="activation__buttons">
          <base-button
            tag="router-link"
            to="/"
            theme="secondary"
            class="activation__button mb-4 mb-tablet-0"
            uppercase
            plain
            >Do strony głównej</base-button
          >
          <base-button
            tag="router-link"
            to="/login"
            theme="primary"
            class="activation__button mb-4 mb-tablet-0"
            uppercase
            plain
            >Zaloguj się</base-button
          >
        </div>
      </div>
    </div>

    <page-footer class="signup__footer" />
  </div>
</template>

<script>
import PageFooter from '@/components/PageFooter/PageFooter';

export default {
  components: {
    PageFooter,
  },
  metaInfo() {
    return {
      title: 'Aktywacja konta',
    };
  },
  data() {
    return {
      loading: false,
      responseStatus: null,
    };
  },
  watch: {
    $route() {
      this.activate();
    },
  },
  async created() {
    this.activate();
  },
  methods: {
    async activate() {
      try {
        this.loading = true;
        const { uid, token } = this.$route.params;
        await this.$api.get(`v0/activate/${uid}/${token}`);
        this.responseStatus = 200;
      } catch (error) {
        this.responseStatus = error.response.status;
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.activation {
  max-width: 75.42rem;
  margin: 0 auto;
  padding: 2rem 2rem 0;

  @include mq('desktop') {
    padding-top: 4rem;
  }
}
.activation__top {
  display: flex;
  justify-content: center;

  @include mq('tablet') {
    justify-content: flex-start;
  }
}
.activation__heading {
  text-align: center;
  font-size: 1.64rem;

  @include mq('tablet') {
    text-align: left;
    font-size: 2.5rem;
  }
}

.activation__content {
  max-width: 59rem;
  font-size: 1.42rem;
}
.activation__paragraph {
  text-align: center;
  font-size: 1.28rem;

  @include mq('tablet') {
    text-align: left;
    font-size: 1.42rem;
  }
}
.activation__buttons {
  padding: 0 2rem;
  display: flex;
  flex-direction: column;

  @include mq('tablet') {
    padding: 0;
    flex-direction: row;
  }
}
.activation__button {
  flex: 1;

  @include mq('tablet') {
    margin-right: 1.5rem;
  }

  &:first-child {
    order: 2;
  }
  &:nth-child(2) {
    order: 3;
  }
  &:last-child {
    order: 1;
  }

  @include mq('tablet') {
    @for $i from 1 through 3 {
      &:nth-child(#{$i}) {
        order: $i;
      }
    }
  }
}
</style>
